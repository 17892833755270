import { useEffect, useState } from "react";
import StudentGrid from "../StudentGrid";
import { Subtitle, Title } from "../../../components";
import Modal from "../../../components/core/Modal";
import { useTutoringUser } from "../../../hooks";
import ChatbaseWidget from "../../../components/features/ChatbaseWidget";
import { BookOpenIcon, ClockIcon, UserGroupIcon } from "@heroicons/react/outline";
import { ContentContainer, PageContainer } from "../../../containers";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import Notification from "../Notification";
import TutorError from "../TutorError";
import LoaderPage from "../../utility/LoaderPage";
import useFomo from "../../../hooks/useFomo";
import Announcements from "./Announcements";
import Tasks from "./Tasks";

export default function Dashboard() {
  useDocumentTitle("Dashboard");
  const [, setStats] = useState([]);
  const { user, tutorError } = useTutoringUser();
  const [studentInfo, setStudentInfo] = useState(null);
  useFomo();
  console.log(studentInfo);
  useEffect(() => {
    if (!user) return;
    const students = user.correspondents || [];
    const tutoringStats = [
      {
        name: "Students",
        icon: UserGroupIcon,
        stat: students.length || 0,
      },
      {
        name: "Sessions",
        icon: BookOpenIcon,
        stat: user.totalSessions || 0,
      },
      {
        name: "Minutes",
        icon: ClockIcon,
        stat: user.minutesTutored || 0,
      },
    ];
    setStats(tutoringStats);
  }, [user, user?.correspondents]);

  if (tutorError) {
    return <TutorError />;
  } else if (!user) {
    return <LoaderPage />;
  } else {
    return (
      <>
        <ChatbaseWidget />
        <PageContainer>
          <ContentContainer className="block lg:hidden md:hidden">
            <Title text="Tutor Portal" theme="green" />
            <Subtitle user={user} />
            {user.correspondents.map((student) => {
              return (
                <div key={student.id} className="flex flex-col gap-2 mt-2">
                  <div className="font-bold text-[25px] text-[#3F3F3F]">{`${student.firstname} ${student.lastname}`}</div>
                  <div className="text-xs text-gray-500 underline hover:cursor-pointer">
                    <button onClick={() => setStudentInfo(student)}>View information</button>
                  </div>
                </div>
              );
            })}
          </ContentContainer>
          <ContentContainer className="hidden lg:block md:block">
            <StudentGrid setStudentInfo={setStudentInfo} />
          </ContentContainer>
          <ContentContainer className="sm:block lg:block md:block">
            <div className="flex flex-col sm:flex-row gap-5 mt-5 w-full">
              <div className="flex-1">
                <Announcements />
              </div>
              <div className="flex-1">
                <Tasks />
              </div>
            </div>
          </ContentContainer>
        </PageContainer>
        <Notification user={user} />
        {studentInfo && (
          <Modal
            close={() => {
              setStudentInfo(null);
            }}
            title="Student Information">
            <StudentModal studentInfo={studentInfo} setStudentInfo={setStudentInfo} />
          </Modal>
        )}
      </>
    );
  }
}

const StudentModal = ({ studentInfo, setStudentInfo }) => {
  const meetingID = studentInfo.information["Tutoring Code"];
  const meetingLink = "https://student-portal.stepuptutoring.org/student/" + meetingID;

  const filteredInfo = Object.entries(studentInfo.information).filter(([key]) => key !== "Student Meeting Link");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(meetingLink);
    alert("Meeting link copied to clipboard");
  };

  return (
    <div className="max-h-[60vh] overflow-y-auto">
      {meetingLink && (
        <>
          <div className="font-bold mb-2">Student Meeting Link</div>
          <div className="mb-4 flex items-center">
            <input
              type="text"
              value={meetingLink}
              readOnly
              className="flex-1 p-2 border rounded mr-2"
              style={{ whiteSpace: "pre-line" }}
            />
            <button onClick={copyToClipboard} className="p-2 bg-gray-400 text-white rounded">
              Copy
            </button>
          </div>
        </>
      )}
      <ul>
        {filteredInfo.map(([key, value]) => (
          <li className="mt-3" key={key} style={{ wordBreak: "break-all", overflowWrap: "break-word" }}>
            <span className="font-bold">{key}:</span> {value}
          </li>
        ))}
      </ul>
    </div>
  );
};
