import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Alert, Tabs, Title } from "../../../components";
import { ContentContainer, PageContainer } from "../../../containers";
import { useOnboardingUser } from "../../../hooks";
import { ALERT_VARIANTS } from "../../../constants/constants";
import BackgroundCheckCA from "./BackgroundCheckCA";
import BackgroundCheckDone from "./BackgroundCheckDone";
import BackgroundCheckOther from "./BackgroundCheckOther";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";

const tabs = [
  {
    name: "Living in or Visiting California",
    label: "Living in or Visiting California",
    content: <BackgroundCheckCA />,
  },
  {
    name: "Residents Outside of California",
    label: "Residents Outside of California",
    content: <BackgroundCheckOther />,
  },
];

export default function BackgroundCheck() {
  useDocumentTitle('Background Check')
  const { user } = useOnboardingUser();
  const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    setCurrentTab(_.first(tabs));
  }, []);

  if (user?.liveScanCompleted) return <BackgroundCheckDone />;

  return (
    currentTab && (
      <PageContainer>
        <Title>Background Check</Title>
        <ContentContainer>
          <p>
            All tutors will be required to complete a background check. Tutors
            are asked to pay for their own background check. If
            you are a college student with need-based financial aid or have
            special circumstances, you may be eligible for reimbursement.
            Unfortunately, prior background checks from other organizations
            cannot be accepted (DOJ rules).
          </p>
        </ContentContainer>

        <ContentContainer>
          <Alert variant={ALERT_VARIANTS.INFO}>
            <p>
              Live outside of California? Check out the Residents Outside of
              California tab.
            </p>
          </Alert>
        </ContentContainer>

        <ContentContainer>
          <Tabs value={currentTab.name} onChange={setCurrentTab} tabs={tabs} />
          <div className="sm:px-12 py-12 space-y-6">{currentTab.content}</div>
        </ContentContainer>
      </PageContainer>
    )
  );
}
