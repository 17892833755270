import React, { useState } from "react";
import { Button, Card, CircularIcon, ExternalLink, Heading, Alert } from "../../../components";
import { ALERT_VARIANTS } from "../../../constants/constants";
import { ChevronUpIcon } from "@heroicons/react/solid";

function AccordionItem({ title, description, content }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="border-b border-gray-200 py-4">
      <button className="flex justify-between items-center w-full text-left" onClick={() => setIsOpen(!isOpen)}>
        <div>
          <span className="font-medium">{title}</span>
          <p className="text-sm text-gray-600 mt-1">{description}</p>
        </div>
        <ChevronUpIcon className={`${isOpen ? "rotate-180 transform" : ""} h-5 w-5 text-turquoise-dark`} />
      </button>
      {isOpen && <div className="mt-4">{content}</div>}
    </div>
  );
}

export default function BackgroundCheckOther() {
  const options = [
    {
      title: "Online Background Check",
      description: "Complete your background check quickly and conveniently online.",
      content: (
        <>
          <Alert variant={ALERT_VARIANTS.INFO}>
            <p>
              This option offers convenience with internet-based completion and quick results, typically within days.
            </p>
          </Alert>
          <Card className="my-2">
            <div className="flex items-baseline mb-4 space-x-4">
              <CircularIcon>1</CircularIcon>
              <Heading>Visit Background Check Website</Heading>
            </div>

            <div className="space-y-4">
              <p>Applicants residing outside of California should visit the Secure Volunteer website.</p>

              <div className="max-w-xs mx-auto">
                <ExternalLink href="https://bib.com/secure-volunteer/step-up-tutoring/Choose-Screen">
                  <Button>Visit Secure Volunteer</Button>
                </ExternalLink>
              </div>
            </div>
          </Card>

          <Card>
            <div className="flex items-baseline mb-4 space-x-4">
              <CircularIcon>2</CircularIcon>
              <Heading>Start Process</Heading>
            </div>
            <p>
              Select the <span className="font-medium">Background Screen Type</span>. Click{" "}
              <span className="font-medium">Continue</span> and complete the questions. Lastly, you will be asked to
              enter your payment information.
            </p>
          </Card>

          <Card>
            <div className="flex items-baseline mb-4 space-x-4">
              <CircularIcon>3</CircularIcon>
              <Heading>Wait for Your Results</Heading>
            </div>

            <p>
              We will let you know when we have received your results. This entire process will only take a couple days,
              so you'll be able to start tutoring in no time!
            </p>
          </Card>
        </>
      ),
      value: "online",
    },
  ];

  return (
    <>
      <Card>
        <Heading>Complete Your Background Check</Heading>
        {options.map((option) => (
          <AccordionItem
            key={option.value}
            title={option.title}
            description={option.description}
            content={option.content}
          />
        ))}
      </Card>
    </>
  );
}
