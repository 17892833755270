import _ from "lodash";
import {useCallback, useContext} from "react";
import {UserContext} from "../contexts";
import {ONBOARDING_TASKS} from "../constants/constants";

export default function useOnboardingUser() {
  const { onboardingUser: user } = useContext(UserContext);

  const isOnboardingTaskCompleted = useCallback((task) => {
    if (!user) return false;
    const {
      liveScanCompleted,
      status,
      waiverCompleted,
      workbookForm1Completed,
      workbookForm2Completed,
      workbookForm3Completed,
      workbookFormReviewCompleted,
    } = user;

    switch (task) {
      case ONBOARDING_TASKS.CHAT:
        return status === "Application Accepted";
      case ONBOARDING_TASKS.WAIVER:
        return waiverCompleted;
      case ONBOARDING_TASKS.WORKBOOK:
        return _.every([
          workbookForm1Completed,
          workbookForm2Completed,
          workbookForm3Completed,
          workbookFormReviewCompleted,
        ]);
      case ONBOARDING_TASKS.BACKGROUND_CHECK:
        return liveScanCompleted;
      default:
        return null;
    }
  },
  [user],
  );
  return { user, isOnboardingTaskCompleted };
}
